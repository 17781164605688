export function AboutUs() {
    return (
        <>
            <div className="page-banner-section section about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-banner-content">
                                <h1>HAKKIMIZDA</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="/">Anasayfa</a>
                                    </li>
                                    <li className="active">HAKKIMIZDA</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-section section pt-120 pb-45">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12 mx-auto">
                            <div className="blog-item mb-75">
                                <div className="content fix">
                                    <h4 className="title">Hakkımızda</h4>
                                    <p>
                                        Rotaile Shop, sanatçı Rota'nın
                                        vizyonundan ve onun kendine has
                                        topluluğundan ilham alarak kurulan bir
                                        tekstil markasıdır.{' '}
                                    </p>
                                    <p>
                                        RS ismi, Rota ve onun aile olarak
                                        gördüğü sadık dinleyicilerinden, yani
                                        “Rotaile” topluluğundan esinlenmiştir.
                                    </p>
                                    <p>
                                        Bu marka, sadece bir alışveriş deneyimi
                                        sunmakla kalmaz; aynı zamanda Rota’nın
                                        değerlerini ve sanatıyla bağ kuran bir
                                        yaşam tarzını temsil eder.
                                    </p>
                                    <blockquote>
                                        <p>
                                            "Rotaile ürünleri, kalite, estetik
                                            ve özgün tasarımı bir araya
                                            getirerek kullanıcılarına
                                            kendilerini ifade etme fırsatı
                                            sunar."
                                        </p>
                                    </blockquote>
                                    <p>
                                        2020’den bu yana toplumun kalıplarına
                                        sıkışmayı reddedenlerin, düzenin dışında
                                        yaşamayı seçenlerin, kendi yolunu
                                        çizenlerin markası olma vizyonunu taşır.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
