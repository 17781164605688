import { useEffect, useState } from 'react';
import { ProductItem } from '../components/ProductItem';
import { useCategory } from '../context/categories.context';
import { useProduct } from '../context/product.context';

export function Home() {
    const products = useProduct();
    const categories = useCategory();
    const [activeCategory, setActiveCategory] = useState<string>('');

    useEffect(() => {
        products.refresh(activeCategory);
    }, [activeCategory]);

    return (
        <div className="product-section section pt-150 pb-120">
            <div className="container">
                <div className="row filter-row">
                    <div className="isotope-product-filter col-xs-12">
                        <span
                            className={`button ${activeCategory === '' ? 'active' : ''}`}
                            onClick={() => setActiveCategory('')}
                        >
                            HEPSI
                        </span>
                        {categories.items.map((item) => (
                            <span
                                key={item.id}
                                className={`button ${activeCategory === item.id ? 'active' : ''}`}
                                onClick={() => setActiveCategory(item.id)}
                            >
                                {item.name}
                            </span>
                        ))}
                    </div>
                </div>

                <div className="isotope-grid row">
                    {products.items.map((item) => (
                        <div
                            className="
                        isotope-item
                        chair
                        home-decor
                        col-lg-3 col-md-4 col-sm-6 col-xs-6
                        mb-50
                    "
                            key={item.id}
                        >
                            <ProductItem product={item} />
                        </div>
                    ))}
                </div>

                <div className="iyzico-banner-home">
                    <img alt="Pay with IyziCo" src="/img/pay-with-iyzico.svg" />
                </div>
            </div>
        </div>
    );
}
