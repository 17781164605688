export function PreInformationConditionsModal() {
    return (
        <>
            <h1>Satış Ön Bilgilendirme Formu </h1>
            <p>Madde 1: Satıcıya İlişkin Bilgiler: </p>
            <p>
                Bulut Sedat Alpman, Seyit Ömer Mahallesi, Cevdetpaşa Caddesi No:
                50, Daire: 5 Fatih / İstanbul. adresinde bulunan Türkiye
                Cumhuriyeti yasaları dahilinde kurulan ve varlığını sürdüren bir
                şahıs şirkettir. 
            </p>
            <p>Madde 2: Ürün Bilgileri </p>
            <p>
                Sözleşme konusu ürün veya ürünlerin Katma Değer Vergisi (KDV)
                dahil toplam fiyatı,  Mal/Ürün veya Hizmetin Cinsi, Miktarı,
                Marka/Modeli, Boyutu, Rengi, Miktarı ve Satış Bedeli web
                sitesinde yer alan "Ürün Detay" alanında belirtildiği gibidir.
                ALICI'nın sipariş vermek için kullanmış olduğu internet
                sitesinin kullanımı nedeniyle tüketicilerden fiyat tarifelerine
                ilişkin herhangi bir ek maliyet talep edilmez. 
            </p>
            <p>Madde 3: Geçerlilik Süresi: </p>
            <p>
                İşbu formda ALICI'ya sunulan ürün veya hizmete ilişkin tüm bilgi
                ve taahhütler, ön bilgilendirmenin yapıldığı, mesafeli satış
                sözleşmesinin akdedildiği tarihte geçerlidir. SATICI'nın
                sonradan değişiklik yapma hakkı saklı olup, SATICI'nın bu formda
                verilen bilgi ve taahhütlerle bağlı olmadığı uyarılmaktadır. 
            </p>
            <p>Madde 4: Ödeme Şekli ve Planı : </p>
            <p>
                Web sitesinden, sadece Kapıda Ödeme ve Iyzico ile Ödeme
                seçenekleriyle alışveriş yapılabilir. Bu ödeme yöntemleriyle
                yapılan alışverişlerde, kredi kartı/banka kartı, üçüncü taraf
                kredi kuruluşları (Haso, Turkcell Kredi, Paycell, Zip) veya
                mobil ödeme gibi seçenekler yer almaz. Bu sözleşmede yer alan
                kredi kartı/banka kartı ile ilgili hükümler, yalnızca mevcut
                ödeme yöntemlerine uygulanmaz. Kapıda Ödeme seçeneği ile ödeme,
                teslimat sırasında nakit ya da kart ile yapılabilir. Iyzico ile
                Ödeme seçeneği ile ise online ödeme, Iyzico ödeme altyapısı
                üzerinden güvenli bir şekilde gerçekleştirilebilir.
            </p>
            <p>
                 Alıcı, mevcut ödeme sistemini okuduğunu ve bilgilendirildiğini
                beyan, kabul ve garanti eder. 
            </p>
            <p>Madde 5: Ürün Teslimatı: </p>
            <p>Ürünün teslimat şekli: </p>
            <p>Teslimat Adresi: </p>
            <p>Gönderilen: </p>
            <p>Fatura Adresi: </p>
            <p>
                Sözleşme konusu ürün, yerleşim yerinin uzaklığına bağlı olarak
                kural olarak normal şartlarda  5 – 7 günlük süreyi ve her
                halükarda 30 günlük yasal süreyi aşmamak koşulu ile ALICI'ya
                veya ALICI'nın gösterdiği adresteki üçüncü kişi veya kuruluşa
                teslim edilir  . Ürün Bilgileri başlıklı maddenin altında yazılı
                olan ürün teslimine ilişkin kargo ücreti ALICI tarafından
                ödenir. Sözleşme konusu ürün, tüketiciden başka bir kişi veya
                kuruluşa teslim edilecek ise, ALICI, teslimat yapılacak üçüncü
                kişinin adı-soyadı/adresi gibi bilgileri SATICI'ya tam ve yazılı
                olarak bildirmekle yükümlüdür. SATICI, teslimat anında Alıcı'nın
                adresinde bulunmadığı durumlarda dahi yükümlülüğünü tam ve
                eksiksiz olarak yerine getirmiş kabul edilecektir. Bu nedenle,
                ALICI'nın ürünü geç teslim almasından kaynaklanan tüm zararlar
                ile ürünün kargo şirketinde bekletilmesinden ve/veya kargonun
                SATICI'ya iade edilmesinden kaynaklanan masraflar da ayrıca
                ALICI'ya aittir. SATICI, mücbir sebepler veya nakliyeyi
                etkileyen veya kesintiye uğratan hava muhalefeti gibi olağanüstü
                durumlar nedeni ile sözleşme konusu ürünü süresinde teslim
                edememesi halinde, durumu ALICI'ya bildirmekle yükümlüdür. Bu
                durumda ALICI, siparişin iptal edilmesini ve/veya teslimatın
                teslimatı engelleyen durumlar ortadan kalkıncaya kadar
                ertelenmesini isteme haklarından birini kullanabilir.  
            </p>
            <p>
                Kargo Ücreti: Sözleşme konusu ürün(ler), sipariş tarihi 30
                günlük süreyi aşmamak koşulu ile her bir ürün için İnternet
                Sitesi'nde ön bilgiler kısmında verilen bilgilere istinaden
                ALICI'nın yerleşim yerinin uzaklığına bağlı olarak ALICI veya
                ALICI'nın gösterdiği adresteki kişi/kuruluşa SATICI'nın
                anlaşmalı kargo şirketi tarafından teslim edilir. Teslimatın
                yapılacağı anlaşmalı kargo firması, işbu Sözleşme'nin
                onaylandığı tarih itibarıyla, SATICI'nın operasyonel koşullarına
                bağlı olarak SATICI tarafından belirlenecek ve ALICI'ya
                bildirilecektir. 
            </p>
            <p>Madde 6: Cayma Hakkı </p>
            <p>
                TÜKETİCİ, teslim edilen ürünün  ayıplı ürün olmaması  durumunda 
                ürünü reddetme/iade etme yoluyla sözleşmeden cayma hakkına sahip
                değildir. Ayıplı ürün teslimatı durumunda Tüketici sözleşmeden
                cayma hakkına sahiptir. Bu hakkın kullanıldığına ilişkin olarak
                SATICI'ya  faks veya elektronik posta ile bildirimde bulunulması
                ve cayma hakkının kullanıldığına ilişkin bildirimlerin
                SATICI'nın yukarıda belirtilen iletişim bilgileri dikkate
                alınarak SATICI'ya gönderilmesi gerekmektedir. Ürün bedeli, 
                tüketicinin cayma işleminin satıcıya ulaştığı tarihten itibaren 
                7  (yedi) gün içinde  ayıplı ürün miktarına karşılık gelen
                sadakat bonusu olarak ALICI'ya iade edilir.  Ayıplı ürün
                nedeniyle sözleşmeden dönülmesi halinde ürün,  Tüketici'ye 
                Satıcı'nın hediyesi olarak sunulacaktır. 
            </p>
            <p>ii Cayma hakkının kullanılamayacağı haller: </p>
            <p>
                Cayma Hakkının kullanılamayacağı durumlar Türk Kanunlarında
                öngörülmüş olup, ALICI aşağıdaki ürünlerin konu edildiği
                sözleşmelerde cayma hakkını kullanma hakkına sahip değildir: 
            </p>
            <p> a. Altın ve altın ürünleri. </p>
            <p>
                b. Değerli ve yarı değerli metal ve taşlardan yapılmış ürünler. 
            </p>
            <p>
                c. İmalat hataları hariç olmak üzere her türlü kumaş ve ebatta
                satılan bazı mallar (kurdeleler, kurdeleler, bordürler). 
            </p>
            <p>d. Parfümeri ve kozmetik ürünler. </p>
            <p>e. Erkekler, kadınlar ve çocuklar için mayo. </p>
            <p>f. Anaokulu ve yenidoğan çocuklar için iç çamaşırı. </p>
            <p>g. Ev kimyasal ürünleri. </p>
            <p>
                h. Kişisel hijyen malzemeleri (diş fırçaları, taraklar, maşalar
                vb.). 
            </p>
            <p>i. Çocuk oyuncakları. </p>
            <p>
                j. Kullanılmış erkek, kadın ve çocuk çorapları, etiketsiz iç
                çamaşırları, üretim hataları hariç. 
            </p>
            <p>k. Gıda için plastik ürünler. </p>
            <p>
                l. Ödemesi yapılmış ve mağazadan kaldırılmış gıda ürünleri (son
                kullanma tarihi içinde). 
            </p>
            <p>Madde 7 : Tüketicilerin Şikayet ve İtirazları: </p>
            <p>
                Türk Kanunları ve idari yönetmeliğine göre, herhangi bir üretim
                hatası veya Web Sitesinde ilan edilenden farklı niteliklere
                sahip ürünler  varsa, müşteri 14 gün içinde malı reddetme veya
                değiştirme veya iade etme hakkına sahiptir. 
            </p>
            <p>
                Ayıplı ürün teslimatı durumunda Tüketici sözleşmeden cayma
                hakkına sahiptir. Bu hakkın kullanıldığına ilişkin olarak
                SATICI'ya faks veya elektronik posta ile bildirimde bulunulması
                ve cayma hakkının kullanıldığına ilişkin bildirimlerin
                SATICI'nın yukarıda belirtilen iletişim bilgileri dikkate
                alınarak SATICI'ya gönderilmesi gerekmektedir. Ürün bedeli,
                tüketicinin cayma işleminin satıcıya ulaştığı tarihten itibaren
                7 (yedi) gün içinde ayıplı ürün miktarına karşılık gelen sadakat
                bonusu olarak ALICI'ya iade edilir. Ayıplı ürün nedeniyle
                sözleşmeden dönülmesi halinde ürün, Tüketici'ye Satıcı'nın
                hediyesi olarak sunulacaktır. 
            </p>
            <p>
                Satıcı, müşteri talebini reddederse, müşterinin Satıcı'ya karşı
                dava açma hakkı vardır.  
            </p>
            <p>SATICI: Bulut Sedat Alpman, +90 544 387 2020 </p>
            <p>
                Seyit Ömer Mahallesi, Cevdetpaşa Caddesi No: 50, Daire: 5 Fatih
                / İstanbul. 
            </p>
            <p>Tarih:   </p>
        </>
    );
}
