import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ProductDetail } from '../types/product.type';
import { ProductSize } from '../types/productSize.type';
import { useProduct } from './product.context';

export interface CartItem {
    product: ProductDetail;
    size: ProductSize;
    quantity: number;
}

interface ICartContext {
    addItem: (productId: string, sizeId: string, quantity: number) => void;
    removeItem: (productId: string) => void;
    clear: () => void;
    items: Array<CartItem>;
}

const CartContext = createContext<ICartContext | undefined>(undefined);

interface CartProviderProps {
    children: ReactNode;
}

export function CartProvider({ children }: CartProviderProps) {
    const { items: products } = useProduct();
    const [items, setItems] = useState<Array<CartItem>>([]);
    const [isItemsRestored, setIsItemsRestored] = useState(false);

    const addItem = useCallback(
        (productId: string, sizeId: string, quantity: number) => {
            const index = items.findIndex((x) => x.product.id === productId);

            const newItems = [...items];
            if (index > -1) {
                newItems[index].quantity += quantity;
            } else {
                const p = products.find((x) => x.id === productId);
                if (!p) {
                    return;
                }

                const size = p.sizes.find((x) => x.id === sizeId);
                if (!size) {
                    return;
                }

                newItems.push({
                    product: p,
                    size,
                    quantity,
                });
            }

            localStorage.setItem(
                'cart',
                JSON.stringify(
                    newItems.map((x) => ({
                        productId: x.product.id,
                        sizeId: x.size.id,
                        quantity: x.quantity,
                    }))
                )
            );
            setItems(newItems);
        },
        [products, items]
    );

    const removeItem = useCallback(
        (productId: string) => {
            const newItems = items.filter((x) => x.product.id !== productId);

            localStorage.setItem(
                'cart',
                JSON.stringify(
                    newItems.map((x) => ({
                        productId: x.product.id,
                        sizeId: x.size.id,
                        quantity: x.quantity,
                    }))
                )
            );
            setItems(newItems);
        },
        [items]
    );
    const clearCart = useCallback(() => {
        setItems([]);
        localStorage.removeItem('cart');
    }, []);

    useEffect(() => {
        if (products.length > 0 && !isItemsRestored) {
            const storedCart = localStorage.getItem('cart');
            if (storedCart) {
                try {
                    const parsedCart = JSON.parse(storedCart);

                    const newItems: Array<CartItem> = parsedCart
                        .map(
                            (x: {
                                productId: string;
                                sizeId: string;
                                quantity: number;
                            }) => {
                                const product = products.find(
                                    (prod) => prod.id === x.productId
                                );
                                const size = product?.sizes.find(
                                    (s) => s.id === x.sizeId
                                );

                                if (product && size) {
                                    return {
                                        product,
                                        size,
                                        quantity: x.quantity,
                                    };
                                }
                                return null;
                            }
                        )
                        .filter(Boolean) as Array<CartItem>;

                    setItems(newItems);
                } catch (error) {
                    console.error('cart restore failed: ', error);
                }
            }
            setIsItemsRestored(true);
        }
    }, [products, isItemsRestored]);

    return (
        <CartContext.Provider
            value={{ items, addItem, removeItem, clear: clearCart }}
        >
            {children}
        </CartContext.Provider>
    );
}

export const useCart = () => {
    const context = useContext(CartContext);

    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }

    return context;
};
