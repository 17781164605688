import axios from 'axios';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './App.css';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { CartProvider } from './context/cart.context';
import { CategoryProvider } from './context/categories.context';
import { ModalProvider } from './context/modal.context';
import { ProductProvider } from './context/product.context';
import { SiteConfigProvider } from './context/siteConfig.context';
import { AboutUs } from './pages/AboutUs';
import { Checkout } from './pages/Checkout';
import { ContactUs } from './pages/ContactUs';
import { Home } from './pages/Home';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

function MainProvider() {
    return (
        <SiteConfigProvider>
            <CategoryProvider>
                <ProductProvider>
                    <CartProvider>
                        <ModalProvider>
                            <Header />
                            <Outlet />
                            <Footer />
                        </ModalProvider>
                    </CartProvider>
                </ProductProvider>
            </CategoryProvider>
        </SiteConfigProvider>
    );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainProvider />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/checkout',
                element: <Checkout />,
            },
            {
                path: '/contact-us',
                element: <ContactUs />,
            },
            {
                path: '/about-us',
                element: <AboutUs />,
            },
        ],
    },
]);

function App() {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}

export default App;
