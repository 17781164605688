export function DistanceSalesContractModal() {
    return (
        <>
            <h1>Mesafeli Satış Sözleşmesi</h1>

            <p>
                <b>Madde 1 - Taraflar</b>
            </p>

            <ol>
                <li>
                    SATICI: Bulut Sedat Alpman, Seyit Ömer Mahallesi, Cevdetpaşa
                    Caddesi No: 50, Daire: 5 Fatih / İstanbul. adresinde bulunan
                    Türkiye Cumhuriyeti yasaları dahilinde kurulan ve varlığını
                    sürdüren bir şahıs şirkettir.
                </li>
                <li>
                    <b>ALICI/MÜŞTERİ:</b>
                </li>
                <li>Ad-Soyad/Ünvanı: </li>
                <li>Adres: </li>
                <li>Telefon: </li>
                <li>E-posta: </li>
            </ol>

            <p>Madde 2- Konu</p>

            <ol>
                <li>
                    İşbu sözleşmenin konusu, ALICI'nın SATICI'ya ait{' '}
                    <a href="https://rotaileshop.com/">rotaileshop.com</a>
                    internet sitesi ("İnternet Sitesi") üzerinden elektronik
                    ortamda siparişini verdiği aşağıda özellikleri ve satış
                    fiyatı belirtilen ürünün satışı ve teslimi ile ilgili olarak
                    tarafların hak ve yükümlülüklerinin Müşteri'nin ürünleri
                    satın aldığı tarihten itibaren üç gün içinde Türkiye
                    Kanunlarını seçmemesi durumunda Azerbaycan Kanunları
                    ("Kanun") ve Satış Yönetmeliği ("Yönetmelik") uyarınca
                    belirlenmesidir,
                </li>
            </ol>

            <p>Madde 3- Sözleşme Konusu Ürün</p>

            <ol>
                <li>
                    Sözleşme konusu ürün veya ürünlerin Katma Değer Vergisi
                    (KDV) dahil toplam fiyatı internet sitesinde belirtildiği
                    gibidir. Mal/Ürün veya Hizmetin Cinsi, Miktarı,
                    Marka/Modeli, Boyutu, Rengi, Miktarı ve Satış Bedeli
                    İnternet Sitesi'nde yer alan "Ürün Detay" alanında
                    belirtildiği gibidir.
                </li>
                <li>
                    Sipariş vermek için kullanmış olduğunuz Web Sitesi’nin
                    kullanımından dolayı tüketicilerden fiyat tarifeleri ile
                    ilgili herhangi bir ek masraf (gümrük ücretleri hariç) talep
                    edilmemektedir.
                </li>
            </ol>

            <p>
                Teslimat Şekli: Teslimat, tedarikçinin anlaşmalı olduğu kargo
                şirketi aracılığıyla ALICI'nın adresinde yapılacaktır.
            </p>

            <p>Teslimat Adresi:</p>

            <p>Teslim Edilecek Kişi:</p>

            <p>Fatura Adresi:</p>

            <p>
                Teslim Tarihi: Gönderiler, siparişin oluşturulduğu tarihten
                itibaren 5 ila 7 iş günü içinde başlatılacaktır. Bu süre,
                siparişlerde veya gümrük işlemlerinde herhangi bir sorun
                yaşanması durumunda, Türk Kanunları gereğince, siparişin
                verildiği tarihten itibaren en fazla 30 güne kadar uzatılabilir.
            </p>

            <p>
                Kurye Ücreti: Sözleşme konusu ürün(ler), sipariş tarihinden
                itibaren 14 günlük süreyi aşmamak koşulu ile SATICI'nın
                anlaşmalı kargo şirketi ile ALICI veya gösterdiği adresteki
                kişi/kuruluşa teslim edilir. Teslimatı gerçekleştirecek olan ve
                iletişime geçilen kargo şirketi, işbu Sözleşme'nin onaylandığı
                ve ALICI'ya tebliğ edildiği tarih itibariyle SATICI'nın
                operasyonel durumu esas alınarak SATICI tarafından
                belirlenecektir.
                <br />
                <br />
                Madde 4- Genel Hükümler
            </p>

            <ol>
                <li>
                    ALICI, internet sitesinde yer alan sözleşme konusu
                    ürün(ler)in temel nitelikleri, satış fiyatı ve ödeme şekli
                    ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi
                    olduğunu ve elektronik ortamda gerekli teyidi verdiğini
                    beyan eder.
                </li>
            </ol>

            <ol start={2}>
                <li>
                    Web sitesinden, sadece Kapıda Ödeme ve Iyzico ile Ödeme
                    seçenekleriyle alışveriş yapılabilir. Bu ödeme yöntemleriyle
                    yapılan alışverişlerde, kredi kartı/banka kartı, üçüncü
                    taraf kredi kuruluşları (Haso, Turkcell Kredi, Paycell, Zip)
                    veya mobil ödeme gibi seçenekler yer almaz. Bu sözleşmede
                    yer alan kredi kartı/banka kartı ile ilgili hükümler,
                    yalnızca mevcut ödeme yöntemlerine uygulanmaz. Kapıda Ödeme
                    seçeneği ile ödeme, teslimat sırasında nakit ya da kart ile
                    yapılabilir. Iyzico ile Ödeme seçeneği ile ise online ödeme,
                    Iyzico ödeme altyapısı üzerinden güvenli bir şekilde
                    gerçekleştirilebilir.
                </li>
            </ol>

            <p>
                Alıcı, mevcut ödeme sistemlerini okuduğunu ve
                bilgilendirildiğini taahhüt eder., Alıcı, işbu sözleşmede
                belirtilen Kredi Kartı/Banka Kartı / Üçüncü Taraf Kredi
                Kuruluşları vasıtasıyla ödeme ile ilgili kural ve koşullara
                uyacağını taahhüt eder.
            </p>

            <ol start={3}>
                <li>
                    Sözleşme konusu ürün(ler) 30 günlük yasal süreyi aşmamak
                    koşulu ile ALICI veya ALICI'nın gösterdiği adresteki
                    kişi/kuruluşa teslim edilir.
                </li>
                <li>
                    Sözleşme konusu ürün(ler)in ALICI'dan başka bir
                    kişi/kuruluşa teslim edilecek olması halinde, teslim alan
                    kişi/kuruluşun teslimatı kabul etmemesinden SATICI sorumlu
                    tutulamaz.
                </li>
                <li>
                    SATICI, sözleşme konusu ürünü hasarsız, eksiksiz, siparişte
                    verilen niteliklere uygun ve mevzuatın gerektirdiği hallerde
                    garanti belgeleri ve kullanma talimatları ile birlikte
                    eksiksiz teslim etmekle yükümlüdür.
                </li>
                <li>
                    SATICI, siparişi verilen ürün veya hizmetin sağlanmasının
                    imkânsızlaşması nedeniyle sözleşme konusu yükümlülüklerini
                    yerine getiremezse, bu durumu öğrendiği tarihten itibaren 3
                    (üç) gün içinde yazılı olarak veya sabit veri sağlayıcısı
                    aracılığıyla tüketiciye bildirir ve tahsil edilen tüm
                    tutarları bu bildirim tarihinden itibaren en geç 7 (yedi)
                    gün içinde ürün meblağına ürünün alındığı ödeme methoduna
                    uygun olarak iade eder.
                </li>
            </ol>

            <ol start={7}>
                <li>
                    İşbu sözleşme konusu ürünün ALICI tarafından İnternet Sitesi
                    üzerinden sipariş oluşturulurken sanal ortamda kabul
                    edilmesi, işbu sözleşmenin ve satış ön bilgilendirme
                    formunun ALICI tarafından incelenmesi , satış bedelinin
                    ALICI tarafından ödenmesi şarttır. Her ne sebeple olursa
                    olsun ürün bedeli ödenmez veya banka kayıtlarında iptal
                    edilir ise, SATICI ürünün teslimi yükümlülüğünden kurtulmuş
                    kabul edilir.
                </li>
            </ol>

            <ol start={8}>
                <li>
                    ALICI'ya ait kredi kartının, ALICI'nın kusurundan
                    kaynaklanmayan bir şekilde yetkisiz kişilerce haksız veya
                    gayrimeşru kullanımı nedeni ile ilgili banka veya finans
                    kuruluşunun ürün bedelini SATICI'ya ödememesi halinde, ALICI
                    ürünün kendisine teslim edilmiş olmasını müteakip 3 (üç) gün
                    içinde ürünü veya ürünün teslimi için ödenen ürün ve hizmet
                    bedelini SATICI'ya iade etmekle yükümlüdür.
                </li>
            </ol>

            <ol start={9}>
                <li>
                    SATICI, mücbir sebepler veya nakliyeyi etkileyen veya
                    kesintiye uğratan hava muhalefeti gibi olağanüstü durumlar
                    nedeni ile sözleşme konusu ürünü süresinde teslim edememesi
                    halinde, durumu ALICI'ya bildirmekle yükümlüdür. Bu durumda
                    ALICI, siparişin iptal edilmesine ilişkin hakkını 15 (on
                    beş) gün süreyle kullanma hakkına sahiptir, ALICI'nın
                    siparişi iptal etmesi halinde ödemiş olduğu tutar 7 (yedi)
                    gün içinde tamamen iade edilir.
                </li>
            </ol>

            <ol start={10}>
                <li>
                    ALICI'nın kredi kartı/ banka kartı/ debit kart ile yapmış
                    olduğu ödemelerde, Taraflarca para iadesinin ödeme methoduna
                    uygun olarak yapılması hususunda mutabık kalındığı takdirde,
                    ürün bedeli, siparişin ALICI tarafından iptal edilmesini
                    takip eden 7 (yedi) gün içinde ilgili bankaya iade edilir.
                    ALICI, SATICI tarafından kredi kartına, ALICI'nın hesabına
                    iade edilen bu tutarın bankaya iade edildikten sonra
                    ALICI'nın hesaplarına yansımasının banka işlem süreci ile
                    ilgili olduğunu, olası gecikmelerde SATICI'nın müdahalesinin
                    mümkün olmadığını ve bankanın tutarı yansıtması için geçecek
                    süreden SATICI'nın sorumlu olmadığını kabul,beyan ve taahhüt
                    eder. Üçüncü taraf kredi kuruluşları ya da mobil ödeme
                    kuruluşları vasıtasıyla yapılan ödemelerde, iade koşulları
                    ilgili kurumlar / kuruluşlar ile akdedilen sözleşmeler
                    kapsamında, ilgili kurum / kuruluşlar takdirinde
                    düzenlenecektir. Bu durumda ALICI, iade hususunun ilgili
                    ödeme kurumu / kuruluşu sorumluluğunda olduğunu ve SATICI’
                    nın sorumlu tutulamayacağını kabul , beyan ve taahhüt eder.
                </li>
            </ol>

            <ol start={11}>
                <li>
                    Banka, ALICI'ya ürünü satın almış olduğu şekilde peşin ve/
                    veya satın almış olduğu taksit sayısında iade etme hakkına
                    sahiptir. Banka POS cihazlarından yapılan taksitli
                    alışverişlerin, SATICI'nın toplam ürün bedelini bankaya
                    doğrudan ödemesinden sonra ALICI'nın kredi kartına iade
                    edildiği durumlarda, müdahillerin mağdur olmaması için talep
                    edilen iade tutarları yine taksitli olarak hamil tarafın
                    hesaplarına aktarılabilecektir. ALICI'nın sipariş iptaline
                    kadar ödemiş olduğu taksit tutarları, iade tarihi kart
                    ekstre tarihleri ile çakışmamak kaydıyla ayda 1 iade olarak
                    karta yansıtılacak olup ALICI'nın iade öncesinde ödemiş
                    olduğu taksit sayısı kadar ay boyunca iade öncesinde ödemiş
                    olduğu taksitleri alacaktır. SATICI, kredi kartı ile satın
                    aldığı ürün ve hizmetlerin iade edildiği durumlarda, banka
                    ile yaptığı sözleşme gereği ALICI'ya nakden geri ödeme
                    yapamaz. Kredi kartına iade, taraflarca iadenin bu şekilde
                    yapılacağının kararlaştırılması akabinde, SATICI'nın tutarı
                    bankaya doğrudan geri ödemesinden sonra, yukarıda belirtilen
                    prosedüre göre banka tarafından yapılabilir.
                </li>
                <li>
                    ALICI’ nın , internet sitesinin kampanyalarında, internet
                    sitesinin satış sisteminde veya internet sitesinde yer alan
                    herhangi bir boşluktan (teknik vb.) faydalanmak suretiyle
                    kampanyalarını, satış sistemini veya internet sitesini
                    kötüye kullandığının ve Bulut Sedat Alpman'a zarar
                    verdiğinin, haksız menfaat sağladığının, mükerrer
                    uygulamalara, yanlış davranışlarda bulunduğuna, kampanya
                    veya satış koşullarından yararlandığının Bulut Sedat Alpman
                    tarafından tespit edilmesi halinde, Bulut Sedat Almpan, bu
                    tür işlemleri iptal etme, kullanıcının üyeliğine derhal son
                    verme ve satış sözleşmesini derhal ve tek taraflı olarak
                    feshetme hakkına sahiptir ve Bulut Sedat Almpan'ın kanundan
                    doğan tüm hakları saklı tutulmaktadır.
                </li>
                <li>
                    ALICI, SATICI tarafından kendisine gönderilecek her türlü
                    ticari elektronik iletiye muvafakat etmektedir. Bu çerçevede
                    ALICI, SATICI'nın herhangi bir kişisel müdahalede
                    bulunmaksızın çalışan faks, elektronik posta, kısa mesaj
                    gibi otomatik arama sistemleri veya diğer iletişim araçları
                    ile bilgilendirme, pazarlama ve/veya tanıtım amaçlı her
                    türlü elektronik ileti/SMS/faks vb. göndermeye hakkı
                    olduğunu kabul ve beyan eder. ALICI, işbu kullanım koşulları
                    kapsamındaki elektronik iletileri aldığı elektronik
                    iletileri dilediği zaman ve hiçbir gerekçe göstermeksizin
                    durdurma hakkına sahip olup; ALICI'nın bu yöndeki talebini,
                    arama veya mesajda verilen iletişim bilgilerini kullanarak
                    SATICI'ya iletmesi yeterli olacaktır. SATICI, cayma
                    talebinin kendisine kolay ve ücretsiz olarak iletilmesini
                    sağlayacak; SATICI ise bu talebi aldığı tarihten itibaren 15
                    (on beş) gün içinde yerine getirecektir
                </li>
                <li>
                    ALICI, işbu sözleşmeyi elektronik ortamda teyit etmekle,
                    siparişi verilen ürünlerin temel özelliklerini, ürünlerin
                    vergiler dahil fiyatını, ödeme ve teslimat detaylarını da
                    içeren, Tedarikçi tarafından Tüketici'ye verilmesi gereken
                    Satış Ön Bilgilendirme Formu'nu doğru ve eksiksiz olarak,
                    mesafeli satış sözleşmelerinin akdedilmesinden önce SATICI
                    tarafından temin edildiğini kabul, beyan ve taahhüt eder.
                </li>
                <li>
                    ALICI, sözleşme konusu ürünün temel nitelikleri, tüm
                    vergiler dahil satış fiyatı ile ödeme ve teslimat şekli, tüm
                    masrafların ALICI tarafından karşılanacağı, teslimat süresi
                    ve SATICI'nın tam ticari unvanı, açık adresi ve iletişim
                    bilgilerine ilişkin olarak İnternet Sitesi'nde yer alan ön
                    bilgileri okuyup anladığını, ve elektronik ortamda gerekli
                    teyidi sağlamıştır.
                </li>
                <li>
                    SATICI, teslimat anında alıcının adresinde bulunmadığı
                    durumlarda dahi yükümlülüğünü tam ve eksiksiz olarak yerine
                    getirmiş kabul edilecektir. Bu nedenle, ürünün alıcı
                    tarafından geç teslim alınmasından dolayı oluşan tüm
                    zararlar ile ürünün kargo şirketinde bekletilmesinden
                    ve/veya kargonun SATICI'ya iade edilmesinden kaynaklanan her
                    türlü masraf da ALICI'ya aittir.
                </li>
                <li>
                    Ayıplı ürün durumunda ALICI’ nın cayma hakkı bulunmakta olup
                    cayma hakkını kullanmaya karar veren ALICI'nın, bu kararını
                    SATICI'ya +900000000000 numaralı Destek Hizmet numarasını
                    arayarak veya support@bulutsedatalpman.com.e-posta adresi
                    vasıtasıyla bildirmesi gerekmektedir.
                </li>
                <li>
                    ALICI, Sözleşme'de belirtilen şartları sağlamak suretiyle,
                    satın aldığı ürünün ayıplı olması halinde, ayıplı mal/lara
                    ait faturayı sunarak sözleşmede düzenlenen cayma hakkına
                    sahiptir. Ödeme makbuzu / faturası web sitesinden
                    indirilebilir. Ürün ücreti tutarı için ürün tutarı iade
                    edilecektir. Ayıplı ürünler, iade işlemleri için müşteriyi
                    meşgul etmemek ve ayıplı ürünler nedeniyle cayma
                    işlemlerinde müşteri memnuniyetini sağlamak için Müşteri
                    nezdinde bırakılacak olup SATICI tarafından geri
                    alınmayacaktır.
                </li>
                <li>
                    ALICI'ya ait kredi kartının, banka kartının, banka kartının
                    veya İnternet Sitesi'nde sunulan diğer ödeme sistemlerinin
                    ALICI'nın kusurundan kaynaklanmaksızın yetkisiz kişilerce
                    haksız veya gayri meşru bir şekilde kullanılması nedeniyle
                    bir banka veya finans kuruluşunun ürün bedelini SATICI'ya
                    ödeyecektir. Aksi takdirde ALICI, kendisine karşı da tüm
                    işlemlerin yapılacağını kabul ve taahhüt eder.
                </li>
                <li>
                    SATICI, ALICI'nın satın aldığı ürünün Satıcı'nın stoklarında
                    bulunmaması halinde, ALICI'nın ödemiş olduğu tutarı geri
                    öder.
                </li>
                <li>
                    SATICI, mücbir sebepler veya nakliyeyi etkileyen ya da
                    kesintiye uğratan hava muhalefeti gibi diğer olağanüstü
                    durumlar nedeni ile sözleşme konusu ürünü süresinde teslim
                    edememesi halinde, durumu ALICI'ya bildirmekle yükümlüdür.
                    ALICI'nın bu şartlar altında siparişi iptal etmesi halinde,
                    ödediği tutar 7 (yedi) gün içinde ürün(ler)i satın alırken
                    kullanmış olduğu ödeme aracına uyumlu olarak veya sadakat
                    bonusu olarak peşin olarak iade edilir.
                </li>
                <li>
                    ALICI'nın kredi kartı ile yapmış olduğu ve Taraflar'ın ürün
                    bedelinin kredi kartına iadesi suretiyle ürün bedelinin geri
                    ödenmesi konusunda anlaştıkları ödemelerde, ALICI'nın
                    siparişi iptal etmesini takip eden 7 (yedi) gün içinde
                    ürün(ler) bedeli ilgili bankaya iade edilir. Bu tutarın
                    iadeyi takiben ALICI'nın hesabına yansıması tamamen banka
                    işlem süreci ile ilgili olduğundan, ALICI olası gecikmelere
                    SATICI'nın herhangi bir şekilde müdahale edemeyeceğini,
                    SATICI tarafından kredi kartına iade edilen tutarın banka
                    tarafından ALICI'nın hesabına yansımasının ortalama 2 (iki)
                    ila 3 (üç) haftayı bulabileceğini peşinen kabul eder.
                </li>
                <li>
                    SATICI, İnternet Sitesi üzerinden ALICI'nın ihtiyaçlarını
                    aşan alışverişleri iptal etme hakkını saklı tutar. SATICI,
                    ALICI'nın ihtiyacını aşan ve toptan satış niteliğindeki
                    alımlarda, satın almanın 3 (üç) ürünü aşması durumunda
                    siparişi tamamen iptal etme veya perakende alım limitleri
                    içinde kalan sadece (üç) ürünü gönderme hakkını saklı tutar.
                </li>
                <li>
                    ALICI'nın kredi kartı ile yaptığı alışverişlerde, 3 (üç)
                    adet hatalı şifre girişi nedeniyle ilgili tutara ilişkin
                    işlemin tamamlanamaması halinde SATICI, ALICI'dan kimlik ve
                    kredi kartı bilgilerini teyit edecek görsel araçlar da dahil
                    olmak üzere her türlü bilgi ve belgeyi talep etme hakkına
                    sahiptir. SATICI, ALICI'nın söz konusu bilgi ve belgeleri
                    sağlamaması, eksik bilgi ve belge vermesi veya verilen bilgi
                    ve belgelerin satış verileriyle çelişmesi halinde, ilgili
                    siparişi derhal ve hiçbir sorumluluk ve tazminat
                    üstlenmeksizin iptal etme hakkına sahiptir.
                </li>
                <li>
                    İnternet sitesinde piyasa fiyatlarının çok altında, ortalama
                    bir insanın açık bir hatanın varlığını anlayabileceği
                    düzeyde ürün fiyatlarının yer aldığı durumlarda, SATICI bu
                    hatalı fiyat üzerinden verilen tüm siparişleri tek taraflı
                    olarak iptal etme hakkına sahiptir. ALICI, bu gibi
                    durumlarda açık bir hatanın varlığı nedeniyle herhangi bir
                    hak ve talep hakkına sahip olmadığını kabul ve beyan eder.
                </li>
                <li>
                    SATICI tarafından sunulan ürünler için kullanılmak üzere
                    İnternet Sitesi'nde indirim veya hediye içeren birden fazla
                    kampanya sunulabilir. ALICI söz konusu kampanyalardan sadece
                    birinden yararlanma hakkına sahiptir, ALICI aynı alışveriş
                    içerisinde toplu olarak birden fazla kampanyadan
                    yararlanamaz. SATICI, sistem hatası nedeniyle Web Sitesi'nde
                    sehven açılan kampanyalardan yararlanarak verdiği
                    siparişleri derhal iptal etme hakkını saklı tutar.
                </li>
            </ol>

            <p>Madde 5- Borçlunun Temerrüdü</p>

            <ol>
                <li>
                    ALICI, SATICI'nın bu yükümlülüğünü yerine getirmesini
                    engelleyebilecek mücbir sebepler veya nakliyeyi engelleyen
                    hava muhalefeti, ulaşımın kesilmesi, yangın, deprem veya sel
                    gibi olağanüstü olaylar nedeni ile sözleşme konusu ürünleri
                    süresi içinde teslim edememesi hallerinde SATICI'nın
                    herhangi bir sorumluluğu olmayacağını kabul eder. Bu durumda
                    ALICI, siparişin iptal edilmesini veya teslimatı engelleyen
                    koşulların ortadan kalkmasına kadar teslimatın ertelenmesini
                    talep etme haklarından herhangi birini kullanma hakkına
                    sahiptir.
                </li>
            </ol>

            <p>Madde 6- Cayma Hakkı</p>

            <ol>
                <li>
                    ALICI, Sözleşme konusu ayıpsız ürünü/ürünleri bizzat
                    kendisine veya gösterdiği adresteki kişi/kuruluşa teslim
                    edildiği durumda cayma hakkına sahip değildir.
                </li>
                <li>
                    ALICI, ürünleri satın aldıktan sonra, ancak kargoya
                    verilmeden önce Kanun kapsamında düzenlenen yedi gün
                    içerisindeki sözleşmeden cayma hakkından feragat ettiğini
                    kabul, beyan ve taahhüt eder.
                </li>
                <li>
                    Alıcı, Satıcı tarafından ayıplı ürün teslimi durumunda
                    sözleşmeyi cayma hakkına sahiptir.
                </li>
                <li>
                    Bu hakkın kullanılması halinde;
                    <ol>
                        <li>
                            ALICI'ya veya bildirmiş olduğu adresteki üçüncü
                            kişiye teslim edilmiş olan ürüne ilişkin faturanın
                            (iade edilmek istenen ürüne ait faturanın kurumsal
                            olması halinde müessese tarafından düzenlenmiş iade
                            faturası ile birlikte) gönderilmesi gerekmektedir.
                            İşletmelere fatura edilen siparişlerin iadeleri,
                            İADE FATURASI düzenlenmediği sürece
                            tamamlanamayabilir,
                        </li>
                        <li>
                            Ürün bedeli, Taraflarca ödemeyi yapmış olduğu
                            şekilde iade hususunda mutabık kalınmadığı takdirde,
                            bu belgelerin SATICI'ya ulaşmasından itibaren 7
                            (yedi) gün içinde ürün tutarlarına tekabül eden
                            sadakat bonusu olarak ALICI'ya iade edilir.
                        </li>
                        <li>
                            SATICI'ya karşı sözleşmeden dönme seçimlik hakkının
                            kullanımı sırasında, ALICI tarafından , ayıplı ürüne
                            ilişkin düzenlenmiş olan faturanın da ibraz edilmesi
                            gerekmektedir.
                        </li>
                        <li>
                            Kampanya kapsamında faydalanılan indirim tutarı,
                            cayma hakkının kullanılması nedeniyle SATICI
                            tarafından düzenlenen kampanya limitinin altına
                            düşülmesi halinde iptal edilir. Fiyat iade
                            taleplerinde ALICI'nın ilgili ürünü satın aldığı
                            tarihteki ürün fiyatları geçerlidir.
                        </li>
                    </ol>
                </li>
                <li>
                    Cayma hakkının ayıplı ürünler bakımından kullanılması
                    halinde, ayıplı ürünler, iade sürecinde müşteri memnuniyeti
                    sağlanması adına Alıcı’ da bırakılacaktır.
                </li>
                <li>
                    Cayma hakkının kullanımından kaynaklanan masraflar,
                    ürünün(lerin) SATICI ‘nın anlaşmalı olduğu kargo firması ile
                    iade gönderilmesi halinde SATICI ‘ya aittir. ALICI’nın
                    kusurundan kaynaklanan bir nedenle malın değerinde bir
                    azalma olursa veya iade imkânsızlaşırsa ALICI kusuru
                    oranında SATICI ‘nın zararlarını tazmin etmekle yükümlüdür.
                    Cayma hakkının kullanılması nedeniyle SATICI tarafından
                    düzenlenen kampanya limit tutarının altına düşülmesi halinde
                    kampanya kapsamında faydalanılan indirim miktarı iptal
                    edilir. Üçüncü taraf kredi kuruluşları ile yapılan
                    alışverişlerde malın teslim edilmesini takip eden otuz gün
                    içerisinde yapılan iadelerde, ürün tutarı ALICI’ya iade
                    edilir. Ödeme kuruluşları ( Banka ve üçüncu taraf kredi
                    kuruluşları gibi.) ve mobil ödeme sistemleri vasıtasıyla
                    yapılan alışverişlerde; işlem ücreti ve sair masrafların
                    iadesi, ilgili 3.taraf ile kurduğunuz sözleşme uyarınca,
                    ilgili ödeme kuruluşu / mobil ödeme hizmeti sağlayan kuruluş
                    inisiyatifindedir.
                </li>
            </ol>

            <p>Madde 7- Cayma Hakkının Kullanılamayacağı Ürünler</p>

            <p>7.1. İşbu sözleşme kapsamında Kanun düzenlemesi gereği ;</p>

            <p>a. Altın ve altın ürünleri.</p>

            <p>
                b. Değerli ve yarı değerli metal ve taşlardan yapılmış ürünler.
            </p>

            <p>
                c. İmalat hataları hariç olmak üzere her türlü kumaş ve ebatta
                satılan bazı mallar (kurdeleler, kurdeleler, bordürler).
            </p>

            <p>d. Parfümeri ve kozmetik ürünler.</p>

            <p>e. Erkekler, kadınlar ve çocuklar için mayo.</p>

            <p>f. Anaokulu ve yenidoğan çocuklar için iç çamaşırı.</p>

            <p>g. Ev kimyasal ürünleri.</p>

            <p>
                h. Kişisel hijyen malzemeleri (diş fırçaları, taraklar, maşalar
                vb.).
            </p>

            <p>i. Çocuk oyuncakları.</p>

            <p>
                j. Kullanılmış erkek, kadın ve çocuk çorapları, etiketsiz iç
                çamaşırları, üretim hataları hariç.
            </p>

            <p>k. Gıda için plastik ürünler.</p>

            <p>
                l. Ödemesi yapılmış ve mağazadan kaldırılmış gıda ürünleri (son
                kullanma tarihi içinde). cayma hakkı kapsamında kabul edilemez.{' '}
                <br />
                <br />
                Madde-8 Kişisel Verilerin Güvenliği ve İşlenmesi
            </p>

            <ol>
                <li>
                    ALICI, kişisel verilerinin işlenmesine ve üçüncü kişilere
                    aktarılmasına Bulut Sedat Alpman'ın Gizlilik ve Çerez
                    politikasını dikkate alarak açıkça muvafakat etmektedir.
                    Kişisel veriler, ALICI'nın İnternet Sitesi üyeliği devam
                    ettiği müddetçe işlenecektir.
                </li>
                <li>
                    Alıcı'ya ait kişisel veriler, daha iyi bir alışveriş
                    deneyimi sunmak, istatistiki verilerin toplanması ve
                    derlenmesi, ticari faaliyetlerinin geliştirilmesi, üyelik
                    sözleşmesinin Şirket'e yüklediği yükümlülüklerin yerine
                    getirilmesi ve benzeri diğer amaçlarla işlenmektedir.
                </li>
                <li>
                    Üye, kişisel verilerinin her ne amaçla olursa olsun muhafaza
                    edilmesi, saklanması ve işlenmesi için yurt içinde veya yurt
                    dışında 3. kişilere iletilmesine açıkça rıza gösterdiğini
                    kabul, beyan ve taahhüt eder.
                </li>
                <li>
                    ALICI'nın kişisel verileri, işbu başvuru formu aracılığıyla
                    veya gerekli görülmesi halinde elektronik ortamda
                    toplanmaktadır.
                </li>
                <li>
                    ALICI, dilediği zaman Veri Sorumlusu Şirket'e başvurarak
                    kişisel verilerinin işlenip işlenmediğini öğrenme, varsa
                    işlenen kişisel verileri hakkında bilgi talep etme, kişisel
                    verilerinin işlenme amacını ve bunların amacına uygun
                    kullanılıp kullanılmadığını öğrenme, kişisel verilerin
                    aktarıldığı üçüncü kişileri bilme, kişisel verilerde oluşan
                    hataların düzeltilmesini isteme ve aktarılmışsa bunların
                    düzeltilmesini isteme, kişisel verilerin işlenmesini
                    gerektiren sebeplerin ortadan kalkması hâlinde kişisel
                    verilerin düzeltilmesini isteme, kişisel verilerin
                    işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde
                    verilerin silinmesini, yok edilmesini veya anonim hale
                    getirilmesini isteme ve, aktarılmışsa bu talebin aktarıldığı
                    üçüncü kişiye iletilmesini isteme, işlenen verilerin sonucu
                    olarak aleyhine bir sonucun ortaya çıkmasına itiraz etme,
                    verilerin işlenmesi sebebiyle zarara uğraması hâlinde
                    zararın giderilmesini Azerbeycan Mahkemeleri nezdinde talep
                    etme hakkına sahiptir.
                </li>
            </ol>

            <p>
                <br />
                Madde 9- Yetkili Mahkeme
            </p>

            <ol>
                <li>
                    İşbu sözleşmenin uygulanmasında, ALICI tarafından işbu
                    sözleşmenin kabul edilmesini takip eden üç gün içerisinde
                    Türkiye Hukuku seçilmediği sürece, Azerbaycan Mahkemeleri
                    yetkilidir. ALICI tarafından siparişin onaylanması halinde,
                    ALICI işbu sözleşmenin tüm koşullarını kabul etmiş sayılır.
                </li>
                <li>Bu Sözleşme ticari amaçlarla yapılmıştır.</li>
            </ol>

            <p>SATICI: Bulut Sedat Alpman, +90 544 387 2020 </p>
            <p>
                Seyit Ömer Mahallesi, Cevdetpaşa Caddesi No: 50, Daire: 5 Fatih
                / İstanbul. 
            </p>

            <p>
                <br />
                <br />
                ALICI: <br />
                Ad-Soyad/Ünvanı: <br />
                Tarih:
            </p>
        </>
    );
}
