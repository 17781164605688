import axios from 'axios';
import { useCallback, useState } from 'react';
import { OrderStatusModal } from '../components/OrderStatusModal';
import { useModal } from '../context/modal.context';

export function ContactUs() {
    const { openModal } = useModal();
    const [payload, setPayload] = useState({
        fullName: '',
        email: '',
        message: '',
    });

    const onSubmit = useCallback(
        async (e: React.FormEvent) => {
            e.preventDefault();
            try {
                await axios.post('/contact-us', {
                    ...payload,
                });

                openModal({
                    content: (
                        <OrderStatusModal
                            state={true}
                            message="Mesajınız başarıyla gönderildi! Sizinle en kısa sürede iletişime geçeceğiz."
                        />
                    ),
                    classNames: { wrapper: 'checkout-state-modal' },
                });
            } catch (error) {
                openModal({
                    content: (
                        <OrderStatusModal
                            state={false}
                            message="Mesaj gönderilirken bir sorun oluştu! Lütfen tekrar deneyin ya da bizimle iletişime geçin."
                        />
                    ),
                    classNames: { wrapper: 'checkout-state-modal' },
                });
            }
        },
        [openModal, payload]
    );

    return (
        <>
            <div className="page-banner-section section about-us">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-banner-content">
                                <h1>İLETİŞİM</h1>
                                <ul className="breadcrumb">
                                    <li>
                                        <a href="/">Anasayfa</a>
                                    </li>
                                    <li className="active">İLETİŞİM</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-section section pt-120 pb-90">
                <div className="container">
                    <div className="row mb-40">
                        <div className="contact-form col-lg-12 col-12 mb-40">
                            <h3>Bizimle iletişime geç</h3>
                            <form id="contact-form" onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-sm-6 col-xs-12 mb-20">
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Ad Soyad"
                                            value={payload.fullName}
                                            onChange={(e) =>
                                                setPayload({
                                                    ...payload,
                                                    fullName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-6 col-xs-12 mb-20">
                                        <input
                                            type="text"
                                            name="email"
                                            placeholder="E-Posta"
                                            value={payload.email}
                                            onChange={(e) =>
                                                setPayload({
                                                    ...payload,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-xs-12 mb-20">
                                        <textarea
                                            name="message"
                                            placeholder="Mesajınız"
                                            value={payload.message}
                                            onChange={(e) =>
                                                setPayload({
                                                    ...payload,
                                                    message: e.target.value,
                                                })
                                            }
                                        ></textarea>
                                    </div>
                                    <div className="col-xs-12">
                                        <input type="submit" value="GÖNDER" />
                                    </div>
                                </div>
                            </form>
                            <p className="form-messege"></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="contact-info col-lg-4 col-12 text-center mb-30">
                            <a href="tel:+905443872020">
                                Telefon: +90 544 387 2020
                            </a>
                        </div>
                        <div className="contact-info col-lg-4 col-12 text-center mb-30">
                            <a href="mailto:iletisim@rotaileshop.com">
                                E-Posta: iletisim@rotaileshop.com
                            </a>
                        </div>
                        <div className="contact-info col-lg-2 col-12 text-center mb-30">
                            <a
                                href="https://www.instagram.com/rotaileshop/"
                                target="_blank"
                            >
                                Instagram
                            </a>
                        </div>
                        <div className="contact-info col-lg-2 col-12 text-center mb-30">
                            <a href="https://x.com/rotaileshop" target="_blank">
                                Twitter / X
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
