import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCart } from '../context/cart.context';
import { useModal } from '../context/modal.context';
import { ProductDetail } from '../types/product.type';

export function ProductItemModal(props: { product: ProductDetail }) {
    const { closeModal } = useModal();
    const cart = useCart();
    const [activeImage, setActiveImage] = useState<number>(0);
    const [selectedSize, setSelectedSize] = useState<number>(0);
    const [itemCount, setItemCount] = useState<number>(1);
    const sizes = useMemo(() => {
        return props.product.sizes.map((item, index) => {
            const stocks = item.stocks.reduce(
                (total, x) => total + x.quantity,
                0
            );
            return (
                <button
                    key={index}
                    className={
                        stocks > 0
                            ? selectedSize === index
                                ? 'active'
                                : ''
                            : 'disable'
                    }
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setSelectedSize(index);
                    }}
                >
                    {item.name}
                </button>
            );
        });
    }, [props.product.sizes, selectedSize]);
    const onAddCart = useCallback(() => {
        cart.addItem(
            props.product.id,
            props.product.sizes[selectedSize]?.id,
            itemCount
        );
        closeModal();
    }, [closeModal, cart, props.product, itemCount, selectedSize]);
    const images = useMemo(() => {
        return [...props.product.files].sort((a, b) => a.order - b.order);
    }, [props.product.files]);

    useEffect(() => {
        setSelectedSize(
            props.product.sizes.findIndex((size) =>
                size.stocks.some((stock) => stock.quantity > 0)
            )
        );
    }, [props.product.sizes]);

    return (
        <div className="row">
            <div className="col-xl-5 col-md-6 col-12 mb-40">
                <div className="tab-content mb-10">
                    {images.map((item, index) => (
                        <div
                            key={item.id}
                            className={`pro-large-img tab-pane ${activeImage === index ? 'active' : ''}`}
                        >
                            <img
                                src={`${process.env.REACT_APP_PRODUCT_IMAGES_URL}/${item.file.medium}`}
                                alt={props.product.name}
                            />
                        </div>
                    ))}
                </div>
                <div className="product-thumbnail-container">
                    {images.map((item, index) => (
                        <div className="product-thumbnail" key={index}>
                            <img
                                onClick={(e) => {
                                    setActiveImage(index);
                                }}
                                src={`${process.env.REACT_APP_PRODUCT_IMAGES_URL}/${item.file.small}`}
                                alt={props.product.name}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-xl-7 col-md-6 col-12 mb-40">
                <div className="product-details section">
                    <h1 className="title">{props.product.name}</h1>
                    <div className="price-ratting section d-flex flex-column flex-sm-row justify-content-between">
                        <span className="price">
                            <span className="new">₺{props.product.price}</span>
                        </span>
                    </div>
                    <div className="short-desc section">
                        <h5 className="pd-sub-title">Açıklama</h5>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: props.product.description,
                            }}
                        />
                    </div>
                    <div className="product-size section">
                        <h5 className="pd-sub-title">Beden</h5>
                        {sizes}
                    </div>

                    {selectedSize > -1 && (
                        <div className="quantity-cart section">
                            <div className="product-quantity">
                                <input
                                    type="text"
                                    value={itemCount}
                                    onChange={(e) => {
                                        setItemCount(Number(e.target.value));
                                    }}
                                />
                            </div>
                            <button
                                className="add-to-cart"
                                onClick={() => onAddCart()}
                            >
                                SEPETE EKLE
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
