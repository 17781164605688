import { useCallback, useMemo } from 'react';
import { useModal } from '../context/modal.context';
import { DiscountTypes, ProductDetail } from '../types/product.type';
import { ProductSize } from '../types/productSize.type';
import { calculateDiscountedPrice } from '../utils/calculateDiscountedPrice';
import { formatCurreny } from '../utils/formatCurrency';
import { ProductItemModal } from './ProductItemModal';

function getProductBanner(p: ProductDetail) {
    if (p.freeShipment) {
        return 'ÜCRETSİZ KARGO';
    }
    if (
        p.discountType === DiscountTypes.PERCENT &&
        p.discountAmount &&
        p.discountAmount > 0
    ) {
        return `SEPETTE %${p.discountAmount} İNDİRİM!`;
    }
    if (
        p.discountType === DiscountTypes.AMOUNT &&
        p.discountAmount &&
        p.discountAmount > 0
    ) {
        return `SEPETTE ${(p.price - p.discountAmount).toFixed(2)}TL!`;
    }
}

function getProductImage(files: ProductDetail['files']): string {
    if (!files || files.length === 0 || !files[0]?.file) {
        return '/small-logo.jpg';
    }

    return `${process.env.REACT_APP_PRODUCT_IMAGES_URL}/${files[0].file.small}`;
}

export function ProductItem({ product }: { product: ProductDetail }) {
    const { openModal } = useModal();
    const onModalOpen = useCallback(() => {
        openModal({ content: <ProductItemModal product={product} /> });
    }, [product, openModal]);
    const totalCount = useMemo(() => {
        return (product.sizes || []).reduce(
            (t, i) => t + i.stocks?.reduce((st, si) => st + si.quantity, 0),
            0
        );
    }, [product]);
    const campaign = useMemo(() => {
        return getProductBanner(product);
    }, [product]);

    const images = useMemo(() => {
        return [...product.files].sort((a, b) => a.order - b.order);
    }, [product.files]);

    return (
        <>
            <div className="product-item text-center">
                <div className="product-img">
                    <span
                        className="image cursor"
                        onClick={() => onModalOpen()}
                    >
                        <img src={getProductImage(images)} alt={product.name} />
                    </span>
                    {totalCount === 0 ? (
                        <div className="product-campaign">TÜKENDİ</div>
                    ) : (
                        campaign && (
                            <div className="product-campaign">{campaign}</div>
                        )
                    )}
                </div>
                <div className="product-info text-left">
                    <h5 className="title">
                        <div onClick={() => onModalOpen()} className="cursor">
                            {product.name}
                            {product.badge && product.badge.length > 0 && (
                                <span className="description">
                                    ({product.badge})
                                </span>
                            )}
                        </div>
                    </h5>
                    <div className="price-ratting fix">
                        <span className="price float-left">
                            {totalCount > 0 && (
                                <span className="new">
                                    {formatCurreny(
                                        'TR',
                                        product.price,
                                        calculateDiscountedPrice({
                                            quantity: 1,
                                            product,
                                            size: {} as ProductSize,
                                        })
                                    )}
                                </span>
                            )}
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
}
