export function formatCurreny(
    country: string,
    amount: number,
    discounted?: number
) {
    const symbol = country == 'TR' ? '₺' : '$';
    if (discounted && amount.toFixed(2) !== discounted.toFixed(2)) {
        return (
            <>
                <span className="linethrough">
                    {symbol}
                    {amount.toFixed(2)}
                </span>{' '}
                {symbol}
                {discounted.toFixed(2)}
            </>
        );
    }
    return (
        <>
            {symbol}
            {amount.toFixed(2)}
        </>
    );
}
