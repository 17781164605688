import { useModal } from '../context/modal.context';
import { DistanceSalesContractModal } from './DistanceSalesContractModal';
import { PreInformationConditionsModal } from './PreInformationConditionsModal';

export function Footer() {
    const { openModal } = useModal();

    return (
        <div className="footer-bottom-section section">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="copyright text-left col-md-auto col-12">
                        <p>Copyright © 2024 Rotaile. All rights reserved.</p>
                    </div>
                    <div className="copyright text-left col-md-auto col-12">
                        <p>
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openModal({
                                        content: (
                                            <PreInformationConditionsModal />
                                        ),
                                    });
                                }}
                            >
                                Ön Bilgilendirme Koşulları
                            </a>{' '}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openModal({
                                        content: <DistanceSalesContractModal />,
                                    });
                                }}
                            >
                                Mesafeli Satış Sözleşmesi
                            </a>
                        </p>
                    </div>
                    <div className="copyright text-right col-md-auto col-12">
                        <p>
                            Powered by{' '}
                            <a href="mailto:info@rotaileshop.com">Rotaile</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
