import { CartItem } from '../context/cart.context';

export function calculateDiscountedPrice(item: CartItem): number {
    if (!item.product.discountType || !item.product.discountAmount) {
        return item.quantity * item.product.price;
    }

    switch (item.product.discountType) {
        case 'AMOUNT':
            return (
                item.quantity *
                Math.max(0, item.product.price - item.product.discountAmount)
            );

        case 'PERCENT':
            const discount = item.product.discountAmount / 100;
            const discountedPrice =
                item.product.price - item.product.price * discount;
            return item.quantity * Math.max(0, discountedPrice);

        default:
            // Invalid or unsupported discount type
            console.error(
                `Unsupported discount type: ${item.product.discountType}`
            );
            return item.quantity * item.product.price;
    }
}
