interface OrderStatusModalProps {
    state: boolean;
    message?: string;
}
export function OrderStatusModal(props: OrderStatusModalProps) {
    return (
        <div>
            <img
                src={props.state ? '/img/check.png' : '/img/failed.png'}
                alt={props.state ? 'Başarılı' : 'Başarısız'}
            />
            <p>
                {props.message && typeof props.message === 'string'
                    ? props.message
                    : props.state
                      ? 'Siparişiniz başarıyla tamamlandı.'
                      : 'Siparişiniz tamamlanamadı. Lütfen tekrar deneyin veya destek ekibimizle iletişime geçin.'}
            </p>
        </div>
    );
}
