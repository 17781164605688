import axios from 'axios';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Category } from '../types/category.type';

interface ICategoryContext {
    refresh: () => Promise<void>;
    items: Array<Category>;
}

const CategoryContext = createContext<ICategoryContext | undefined>(undefined);

interface CategoryProviderProps {
    children: ReactNode;
}

export function CategoryProvider({ children }: CategoryProviderProps) {
    const [items, setItems] = useState<Array<Category>>([]);

    const fetchCategories = useCallback(async () => {
        const res = await axios.get('/categories/active');
        setItems(res.data);
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    return (
        <CategoryContext.Provider value={{ items, refresh: fetchCategories }}>
            {children}
        </CategoryContext.Provider>
    );
}

export const useCategory = () => {
    const context = useContext(CategoryContext);

    if (!context) {
        throw new Error('useCategory must be used within a ProductProvider');
    }

    return context;
};
