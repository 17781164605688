import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCart } from '../context/cart.context';
import { useSiteConfig } from '../context/siteConfig.context';
import { calculateDiscountedPrice } from '../utils/calculateDiscountedPrice';
import { formatCurreny } from '../utils/formatCurrency';

export function Header() {
    const siteConfig = useSiteConfig();
    const { items, removeItem } = useCart();
    const [cartOpen, setCartOpen] = useState(false);
    const cartToggleRef = useRef<HTMLSpanElement>(null);
    const onCartToggle = useCallback(() => {
        setCartOpen(!cartOpen);
    }, [cartOpen]);

    const totalCartCost = useMemo(() => {
        return items.reduce((t, i) => t + i.quantity * i.product.price, 0);
    }, [items]);
    const discountedCartCost = useMemo(() => {
        return items
            .map(calculateDiscountedPrice)
            .filter((x) => x !== -1)
            .reduce((t, i) => t + i, 0);
    }, [items]);
    const freeShipment = useMemo(() => {
        return items.findIndex((x) => x.product.freeShipment) > -1;
    }, [items]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                cartToggleRef.current &&
                !cartToggleRef.current.contains(event.target as Node)
            ) {
                setCartOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <header className="header-section section sticker">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <div className="header-logo float-left">
                            <a href="/">
                                <img src="/logo.png" alt="main logo" />
                            </a>
                        </div>
                        <div className="float-right menu-bar-bar">
                            <div className="header-option-btns float-right">
                                <div className="header-cart float-left">
                                    <span
                                        ref={cartToggleRef}
                                        className="cart-toggle cursor"
                                        data-toggle="dropdown"
                                        onClick={onCartToggle}
                                    >
                                        <i className="pe-7s-cart"></i>
                                        {items.length > 0 && (
                                            <span>{items.length}</span>
                                        )}
                                    </span>
                                    <div
                                        className={`mini-cart-brief dropdown-menu text-left ${cartOpen ? 'active' : ''}`}
                                    >
                                        <div className="all-cart-product clearfix">
                                            {items.map((item) => (
                                                <div
                                                    key={item.product.id}
                                                    className="single-cart clearfix"
                                                >
                                                    <div className="cart-image">
                                                        <img
                                                            src={`${process.env.REACT_APP_PRODUCT_IMAGES_URL}/${item.product.files[0]?.file?.small}`}
                                                            alt={
                                                                item.product
                                                                    .name
                                                            }
                                                        />
                                                    </div>
                                                    <div className="cart-info">
                                                        <h5>
                                                            {item.product.name}
                                                        </h5>
                                                        <p>
                                                            {item.quantity} X{' '}
                                                            <span>
                                                                {formatCurreny(
                                                                    'TR',
                                                                    item.product
                                                                        .price *
                                                                        item.quantity,
                                                                    calculateDiscountedPrice(
                                                                        item
                                                                    )
                                                                )}
                                                            </span>
                                                        </p>
                                                        <span
                                                            className="cart-delete cursor"
                                                            title="Remove this item"
                                                            onClick={() => {
                                                                removeItem(
                                                                    item.product
                                                                        .id
                                                                );
                                                            }}
                                                        >
                                                            <i className="pe-7s-trash" />
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {items.length > 0 && (
                                            <div className="cart-totals">
                                                <h5>
                                                    Kargo
                                                    <span>
                                                        {formatCurreny(
                                                            'TR',
                                                            siteConfig.config
                                                                .shipmentFee,
                                                            freeShipment
                                                                ? 0
                                                                : siteConfig
                                                                      .config
                                                                      .discountedShipmentFee
                                                        )}
                                                    </span>
                                                </h5>
                                                <h5>
                                                    Toplam
                                                    <span>
                                                        {formatCurreny(
                                                            'TR',
                                                            totalCartCost,
                                                            discountedCartCost
                                                        )}
                                                    </span>
                                                </h5>
                                            </div>
                                        )}
                                        <div className="cart-bottom clearfix">
                                            {items.length > 0 ? (
                                                <a
                                                    href="/checkout"
                                                    onClick={onCartToggle}
                                                >
                                                    alışverişi tamamla
                                                </a>
                                            ) : (
                                                <div className="no-item-cart">
                                                    Sepetinizde ürün
                                                    bulunmamaktadır.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav className="main-menu menu-right float-right">
                                <ul>
                                    <li className="active">
                                        <a href="/">ANASAYFA</a>
                                    </li>
                                    <li>
                                        <a href="/contact-us">İLETİŞİM</a>
                                    </li>
                                    <li>
                                        <a href="/about-us">HAKKIMIZDA</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}
