import axios from 'axios';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { SiteConfig } from '../types/siteConfig.type';

interface ISiteConfigContext {
    refresh: () => Promise<void>;
    config: SiteConfig;
}

const SiteConfigContext = createContext<ISiteConfigContext | undefined>(
    undefined
);

interface SiteConfigProviderProps {
    children: ReactNode;
}

export function SiteConfigProvider({ children }: SiteConfigProviderProps) {
    const [config, setConfig] = useState<SiteConfig>({
        shipmentFee: 0,
        discountedShipmentFee: 0,
        abroadShipmentFee: 0,
        discountedAbroadShipmentFee: 0,
        email: '',
        phoneNumber: '',
    });

    const fetchSiteConfig = useCallback(async () => {
        const res = await axios.get('/site-config');
        setConfig(res.data);
    }, []);

    useEffect(() => {
        fetchSiteConfig();
    }, [fetchSiteConfig]);

    return (
        <SiteConfigContext.Provider
            value={{ config, refresh: fetchSiteConfig }}
        >
            {children}
        </SiteConfigContext.Provider>
    );
}

export const useSiteConfig = () => {
    const context = useContext(SiteConfigContext);

    if (!context) {
        throw new Error(
            'useSiteConfig must be used within a SiteConfigProvider'
        );
    }

    return context;
};
