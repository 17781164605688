import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CitySelectInput } from '../components/CitySelect';
import { CountrySelectInput } from '../components/CountrySelect';
import { DistanceSalesContractModal } from '../components/DistanceSalesContractModal';
import { OrderStatusModal } from '../components/OrderStatusModal';
import { PreInformationConditionsModal } from '../components/PreInformationConditionsModal';
import { useCart } from '../context/cart.context';
import { useModal } from '../context/modal.context';
import { useSiteConfig } from '../context/siteConfig.context';
import {
    CreateOrderRequest,
    CreateOrderResponse,
    PaymentMethods,
} from '../types/order.type';
import { calculateDiscountedPrice } from '../utils/calculateDiscountedPrice';
import { formatCurreny } from '../utils/formatCurrency';

export function Checkout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { openModal } = useModal();
    const siteConfig = useSiteConfig();
    const cart = useCart();
    const [payload, setPayload] = useState<CreateOrderRequest>({
        id: '',
        country: 'TR',
        city: '',
        district: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        address: '',
        note: '',
        paymentMethod: PaymentMethods.ON_DELIVERY,
        agreement: false,
        cart: [],
    });
    const totalCartCost = useMemo(() => {
        const priceKey = payload.country === 'TR' ? 'price' : 'abroadPrice';
        return (
            cart.items.reduce(
                (t, i) => t + i.quantity * i.product[priceKey],
                0
            ) +
            (payload.country === 'TR'
                ? siteConfig.config.shipmentFee
                : siteConfig.config.abroadShipmentFee)
        );
    }, [
        payload.country,
        cart.items,
        siteConfig.config.abroadShipmentFee,
        siteConfig.config.shipmentFee,
    ]);
    const discountedCartCost = useMemo(() => {
        if (payload.country !== 'TR') {
            return -1;
        }

        return (
            cart.items
                .map(calculateDiscountedPrice)
                .filter((x) => x !== -1)
                .reduce((t, i) => t + i, 0) +
            siteConfig.config.discountedShipmentFee
        );
    }, [payload.country, cart.items, siteConfig.config.discountedShipmentFee]);
    const onFieldChange = useCallback(
        function <K extends keyof CreateOrderRequest>(
            key: K,
            value: CreateOrderRequest[K]
        ) {
            setPayload({
                ...payload,
                [key]: value,
            });
        },
        [payload]
    );

    const onSubmitOrder = useCallback(async () => {
        if (cart.items.length === 0) {
            alert('Sepetinizde urun yok!');
            return;
        }
        if (payload.city.length === 0) {
            alert('Sehir secmeniz gerekiyor!');
            return;
        }
        if (payload.district.length === 0) {
            alert('Ilce girmeniz gerekiyor!');
            return;
        }
        if (payload.firstName.length === 0) {
            alert('Isim girmeniz gerekiyor!');
            return;
        }
        if (payload.lastName.length === 0) {
            alert('Soyisim girmeniz gerekiyor!');
            return;
        }
        if (payload.email.length === 0) {
            alert('E-Posta girmeniz gerekiyor!');
            return;
        }
        if (payload.phoneNumber.length === 0) {
            alert('Telefon numarasi girmeniz gerekiyor!');
            return;
        }
        if (payload.address.length === 0) {
            alert('Adres girmeniz gerekiyor!');
            return;
        }
        if (!payload.agreement) {
            alert('Sozlesmeleri kabul etmeniz gerekiyor!');
            return;
        }
        if (payload.id.length === 0) {
            alert('TCKN girmeniz gerekiyor!');
            return;
        }

        try {
            const res = await axios.post<CreateOrderResponse>('/orders', {
                ...payload,
                cart: cart.items.map((x) => ({
                    productSizeId: x.size.id,
                    quantity: x.quantity,
                })),
            });

            if (res.data.redirectUri) {
                window.location.replace(res.data.redirectUri);
            } else {
                if (res.data.status === 'success') {
                    cart.clear();
                    alert('Siparisiniz basariyla alindi!');
                    navigate('/');
                } else {
                    openModal({
                        content: <OrderStatusModal state={false} />,
                        classNames: {
                            wrapper: 'checkout-state-modal',
                        },
                    });
                }
            }
        } catch (error) {
            console.error('Something went wrong during create order: ', error);

            const err = error as any;

            if (err.response) {
                console.log({ data: err.response.data });
                openModal({
                    content: (
                        <OrderStatusModal
                            state={false}
                            message={err.response.data?.error}
                        />
                    ),
                    classNames: {
                        wrapper: 'checkout-state-modal',
                    },
                });
            } else {
                openModal({
                    content: <OrderStatusModal state={false} />,
                    classNames: {
                        wrapper: 'checkout-state-modal',
                    },
                });
            }
        }
    }, [payload, cart, navigate]);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const message = params.get('message');

        if (message) {
            if (message === 'success') {
                cart.clear();
                openModal({
                    content: <OrderStatusModal state={true} />,
                    onClose: () => {
                        navigate('/', { replace: true });
                    },
                    classNames: {
                        wrapper: 'checkout-state-modal',
                    },
                });
            } else if (message === 'failed') {
                openModal({
                    content: <OrderStatusModal state={false} />,
                    onClose: () => {
                        const currentPath = location.pathname;
                        navigate(currentPath, { replace: true });
                    },
                    classNames: {
                        wrapper: 'checkout-state-modal',
                    },
                });
            }
        }
    }, [cart, location.pathname, navigate, openModal]);

    return (
        <div className="page-section section pt-150 pb-80">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="checkout-form">
                            <div className="row">
                                <div className="col-lg-6 mb-40">
                                    <form action="#" onSubmit={onSubmitOrder}>
                                        <h3>SİPARİŞ DETAYLARı</h3>
                                        <div className="row">
                                            <div className="col-xs-12 mb-30">
                                                <CountrySelectInput
                                                    value={payload.country}
                                                    onChange={(val) =>
                                                        onFieldChange(
                                                            'country',
                                                            val
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 mb-30">
                                                <CitySelectInput
                                                    value={payload.city}
                                                    onChange={(val) =>
                                                        onFieldChange(
                                                            'city',
                                                            val
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 mb-30">
                                                <label htmlFor="b_l_name">
                                                    İlçe{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="b_l_name"
                                                    type="text"
                                                    value={payload.district}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'district',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="col-sm-12 col-xs-12 mb-30">
                                                <label htmlFor="b_c_name">
                                                    TC Kimlik Numarasi{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="b_c_name"
                                                    type="text"
                                                    value={payload.id}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'id',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 mb-30">
                                                <label htmlFor="b_c_name">
                                                    İsim{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="b_c_name"
                                                    type="text"
                                                    value={payload.firstName}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'firstName',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-6 col-xs-12 mb-30">
                                                <label>
                                                    Soyisim{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={payload.lastName}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'lastName',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-xs-12 mb-30">
                                                <label>
                                                    E-Posta{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    type="text"
                                                    value={payload.email}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'email',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="col-xs-12 mb-30">
                                                <label htmlFor="b_city">
                                                    Telefon Numarası{' '}
                                                    <span className="required">
                                                        *
                                                    </span>
                                                </label>
                                                <input
                                                    id="b_city"
                                                    type="text"
                                                    value={payload.phoneNumber}
                                                    onChange={(e) =>
                                                        onFieldChange(
                                                            'phoneNumber',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>

                                            <div className="col-xs-12 mb-30">
                                                <div className="order-notes">
                                                    <label htmlFor="order_note">
                                                        Adres{' '}
                                                        <span className="required">
                                                            *
                                                        </span>
                                                    </label>
                                                    <textarea
                                                        id="order_address"
                                                        value={payload.address}
                                                        onChange={(e) =>
                                                            onFieldChange(
                                                                'address',
                                                                e.target.value
                                                            )
                                                        }
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 mb-30">
                                                <div className="order-notes">
                                                    <label htmlFor="order_note">
                                                        Mesaj
                                                    </label>
                                                    <textarea
                                                        id="order_message"
                                                        value={payload.note}
                                                        onChange={(e) =>
                                                            onFieldChange(
                                                                'note',
                                                                e.target.value
                                                            )
                                                        }
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-lg-6 col-12 mb-40">
                                    <h3>ÖDEME</h3>
                                    <div className="coupon-form mb-30">
                                        <h3>Sözleşmeler</h3>
                                        <p>
                                            <input
                                                id="agreements"
                                                className="radio-btn"
                                                type="checkbox"
                                                checked={payload.agreement}
                                                onChange={(e) =>
                                                    onFieldChange(
                                                        'agreement',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            <label
                                                htmlFor="payment_cash"
                                                className="radio-label"
                                            >
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        openModal({
                                                            content: (
                                                                <PreInformationConditionsModal />
                                                            ),
                                                        });
                                                    }}
                                                >
                                                    Ön Bilgilendirme Koşulları
                                                </a>
                                                'nı ve{' '}
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        openModal({
                                                            content: (
                                                                <DistanceSalesContractModal />
                                                            ),
                                                        });
                                                    }}
                                                >
                                                    Mesafeli Satış Sözleşmesi
                                                </a>
                                                'ni okudum, onaylıyorum.
                                            </label>
                                        </p>
                                    </div>
                                    <h3>
                                        <input
                                            id="payment_cash"
                                            className="radio-btn"
                                            type="radio"
                                            value="cash"
                                            checked={
                                                payload.paymentMethod ===
                                                PaymentMethods.ON_DELIVERY
                                            }
                                            onChange={() => {
                                                onFieldChange(
                                                    'paymentMethod',
                                                    PaymentMethods.ON_DELIVERY
                                                );
                                            }}
                                        />
                                        <label
                                            htmlFor="payment_cash"
                                            className="radio-label"
                                        >
                                            Kapıda Ödeme
                                        </label>
                                    </h3>
                                    <h3>
                                        <input
                                            id="payment_card"
                                            className="radio-btn"
                                            type="radio"
                                            value="online"
                                            checked={
                                                payload.paymentMethod ===
                                                PaymentMethods.ONLINE
                                            }
                                            onChange={() => {
                                                onFieldChange(
                                                    'paymentMethod',
                                                    PaymentMethods.ONLINE
                                                );
                                            }}
                                        />
                                        <label
                                            htmlFor="payment_card"
                                            className="radio-label"
                                        >
                                            Online Ödeme
                                        </label>
                                    </h3>
                                    <div className="order-wrapper">
                                        <h3>SİPARİŞİNİZ</h3>
                                        <div className="order-table table-responsive mb-30">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="product-name">
                                                            ÜRÜN
                                                        </th>
                                                        <th className="product-total">
                                                            Tutar
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cart.items.map(
                                                        (item, index) => (
                                                            <tr key={index}>
                                                                <td className="product-name">
                                                                    {
                                                                        item
                                                                            .product
                                                                            .name
                                                                    }{' '}
                                                                    {
                                                                        item
                                                                            .size
                                                                            .name
                                                                    }
                                                                    <strong className="product-qty">
                                                                        {' '}
                                                                        ×{' '}
                                                                        {
                                                                            item.quantity
                                                                        }
                                                                    </strong>
                                                                </td>
                                                                <td className="product-total">
                                                                    <span className="amount">
                                                                        {formatCurreny(
                                                                            payload.country,
                                                                            (payload.country ===
                                                                            'TR'
                                                                                ? item
                                                                                      .product
                                                                                      .price
                                                                                : item
                                                                                      .product
                                                                                      .abroadPrice) *
                                                                                item.quantity,
                                                                            calculateDiscountedPrice(
                                                                                item
                                                                            )
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>Kargo</th>
                                                        <td>
                                                            {payload.country ===
                                                            'TR'
                                                                ? formatCurreny(
                                                                      payload.country,
                                                                      siteConfig
                                                                          .config
                                                                          .shipmentFee,
                                                                      siteConfig
                                                                          .config
                                                                          .discountedShipmentFee
                                                                  )
                                                                : formatCurreny(
                                                                      payload.country,
                                                                      siteConfig
                                                                          .config
                                                                          .abroadShipmentFee,
                                                                      siteConfig
                                                                          .config
                                                                          .discountedAbroadShipmentFee
                                                                  )}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Toplam</th>
                                                        <td>
                                                            <strong>
                                                                {formatCurreny(
                                                                    payload.country,
                                                                    totalCartCost,
                                                                    discountedCartCost
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                        <div className="payment-method">
                                            <div className="order-button clearfix">
                                                <button
                                                    type="button"
                                                    onClick={onSubmitOrder}
                                                >
                                                    SİPARİŞİ TAMAMLA
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <img
                                        alt="Pay with IyziCo"
                                        className="payment-method-online-img"
                                        src="/img/pay-with-iyzico.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
