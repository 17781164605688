export enum PaymentMethods {
    ONLINE = 'ONLINE',
    ON_DELIVERY = 'ON_DELIVERY',
}

export type CreateOrderRequest = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    id: string;
    country: string;
    city: string;
    district: string;
    address: string;
    cart: Array<{
        productSizeId: string;
        quantity: number;
    }>;
    paymentMethod: PaymentMethods;
    agreement: boolean;
    note?: string;
};

export type CreateOrderResponse = {
    redirectUri: string;
    status: string;
};
