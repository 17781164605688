import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    ReactNode,
    useEffect,
} from 'react';

type ModalContent = ReactNode | null;

interface ModalOpenParams {
    content: ModalContent;
    onClose?: () => void;
    classNames?: {
        wrapper?: string;
    };
}

interface ModalContextType {
    isOpen: boolean;
    openModal: (params: ModalOpenParams) => void;
    closeModal: () => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [modalParams, setModalParams] = useState<ModalOpenParams | null>();

    const openModal = useCallback((params: ModalOpenParams) => {
        setModalParams(params);
        setIsOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        if (modalParams?.onClose) modalParams.onClose();
        setIsOpen(false);
        setModalParams(null);
    }, [modalParams]);
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
            document.documentElement.style.overflow = '';
        };
    }, [isOpen]);

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal }}>
            {children}
            {isOpen && (
                <>
                    <div
                        className={`${modalParams?.classNames?.wrapper || ''} modal fade show`}
                        id="quickViewModal"
                        tabIndex={-1}
                        onClick={() => closeModal()}
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => closeModal()}
                                    ></button>
                                </div>
                                <div className="modal-body">
                                    {modalParams?.content}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="modal-backdrop fade show"
                        onClick={() => closeModal()}
                    />
                </>
            )}
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);
    if (!context) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};
