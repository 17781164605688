import axios from 'axios';
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { ProductDetail } from '../types/product.type';

interface IProductContext {
    refresh: (categoryId: string) => Promise<void>;
    items: Array<ProductDetail>;
}

const ProductContext = createContext<IProductContext | undefined>(undefined);

interface ProductProviderProps {
    children: ReactNode;
}

export function ProductProvider({ children }: ProductProviderProps) {
    const [items, setItems] = useState<Array<ProductDetail>>([]);

    const fetchProducts = useCallback(async (categoryId: string) => {
        const res = await axios.get('/products/active', {
            headers: {
                'X-Category-Id': categoryId,
            },
        });
        setItems(res.data);
    }, []);

    useEffect(() => {
        fetchProducts('');
    }, [fetchProducts]);

    return (
        <ProductContext.Provider value={{ items, refresh: fetchProducts }}>
            {children}
        </ProductContext.Provider>
    );
}

export const useProduct = () => {
    const context = useContext(ProductContext);

    if (!context) {
        throw new Error('useProduct must be used within a ProductProvider');
    }

    return context;
};
